import styled from 'styled-components';
import { PageWrapper } from '../components/layout/PageWrapper';
import {
  HeadingSection,
  SubtitleSection,
} from '../components/styles/Typography';
import { ButtonNormal } from '../components/ui/Buttons';
import { isSSR } from '../utils/misc';

const Wrapper = styled.section`
  background: var(--gold-3);
  padding: var(--gap-2) var(--padding) var(--wave-offset-padding) var(--padding);
  margin-bottom: var(--wave-offset-negative-margin);
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 680px) {
    padding: var(--padding) var(--padding) var(--wave-offset-padding)
      var(--padding);
  }
`;

const Container = styled.div`
  width: 800px;
  display: grid;
  row-gap: var(--gap-6);
  height: min-content;
  justify-items: center;

  & h1,
  h2,
  p {
    text-align: center;
  }

  @media screen and (max-width: 680px) {
    padding: var(--gap-6);
  }
`;

const Number = styled.h2`
  font-size: 110px;
  color: var(--gold-2);
  line-height: 1.2;
`;

const itData = {
  title: 'Errore 404',
  description: 'La pagina che cerchi non esiste.',
  heading: 'Oops. La pagina che cerchi non esiste.',
  subtitle:
    "Sicuramente la pagina è stata rimossa o l'indirizzo è stato digitato male.",
};

const enData = {
  title: '404 Error',
  description: 'The page doesnl&apos;t exist.',
  heading: "Oops. The page you're looking for doesn't exist.",
  subtitle:
    'Most likely the page has been deleted or therel&apos;s a typo in the address you entered.',
};

const NotFoundPage = () => {
  if (isSSR) {
    return null;
  }
  if (localStorage.lang === 'en-US') {
    return (
      <PageWrapper
        pageData={{ locale: 'en-US', id: null }}
        seoTitle={enData.title}
        seoDescription={enData.description}
      >
        <Wrapper>
          <Container>
            <Number>404</Number>
            <HeadingSection blueHeading>{enData.heading}</HeadingSection>
            <SubtitleSection>{enData.subtitle}</SubtitleSection>
            <ButtonNormal
              $bolder
              $buttonGold
              $hasTopMargin
              buttonLink="/en-US"
              buttonText="Back to home"
            />
          </Container>
        </Wrapper>
      </PageWrapper>
    );
  }
  return (
    <PageWrapper
      pageData={{ locale: 'it-IT', id: null }}
      seoTitle={itData.title}
      seoDescription={itData.description}
    >
      <Wrapper>
        <Container>
          <Number>404</Number>
          <HeadingSection blueHeading>{itData.heading}</HeadingSection>
          <SubtitleSection>{itData.subtitle}</SubtitleSection>
          <ButtonNormal
            $bolder
            $buttonGold
            $hasTopMargin
            buttonLink="/"
            buttonText="Torna alla home"
          />
        </Container>
      </Wrapper>
    </PageWrapper>
  );
};

export default NotFoundPage;
